import Vue from 'vue';


const DataService = {

	install(Vue/*, options*/){

		let api = {

			common: {
				getSelectedFromString: function (options, str){
					let selected = [];
					if (str) {
						str += '';
						let itemList = str.split(',').map(r => +r).filter(r => r);
						options.forEach(o => {
							if (itemList.indexOf(o.v) > -1) {
								selected.push(o);
							}
						});
					}
					return selected;
				}
			},

			general: {
				exchangeOptions: [
					{v: 'bucksense', t: 'BuckSense'},
					{v: 'kayzen', t: 'Kayzen'},
					{v: 'mediasmart', t: 'MediaSmart'},
				],
				mmpOptions: [
					{v: 'appsflyer', t: 'AppsFlyer'},
					{v: 'kochava', t: 'Kochava'},
					{v: 'adjust', t: 'Adjust'},
					{v: 'singular', t: 'Singular'},
					{v: 'branch', t: 'Branch'},
					{v: 'tenjin', t: 'Tenjin'},
					{v: 'tune', t: 'Tune'},
				],
				platformOptions: [
					{v: 'android', t: 'android'},
					{v: 'ios', t: 'ios'},
					{v: 'web', t: 'web'},
				],
				placementStatusOptions: [
					{v: 'draft', t: 'Draft'},
					{v: 'live', t: 'Live'},
					{v: 'paused', t: 'Paused'},
					{v: 'canceled', t: 'Canceled'},
				],
				offerStatusOptions: [
					{v: 'draft', t: 'Draft'},
					{v: 'live', t: 'Live'},
					{v: 'paused', t: 'Paused'},
					{v: 'canceled', t: 'Canceled'},
				],
				campaignStatusOptions: [
					{v: 'draft', t: 'Draft'},
					{v: 'live', t: 'Live'},
					{v: 'paused', t: 'Paused'},
					{v: 'canceled', t: 'Canceled'},
				],
				offerTypeOptions: [
					{v: 'offer', t: 'Normal'},
					{v: 'asset', t: 'Asset'},
					{v: 'custom', t: 'Custom'},
					{v: 'targeting', t: 'Targeting'},
				],

				publisherFeedStatusOptions: [
					{v: 0, t: 'Draft'},
					{v: 1, t: 'Active'},
					{v: 2, t: 'Paused'},
					{v: 3, t: 'Canceled'},
				],
				publisherFeedTypeOptions: [
					{v: 0, t: 'In-App'},
					{v: 1, t: 'Pop'},
					{v: 2, t: 'Push'},
					{v: 3, t: 'Extension'},
					{v: 4, t: 'Domain'},
					{v: 5, t: 'Video'},
				],
				currencyOptions: [
					{v: 'USD', t: 'USD'},
					{v: '%', t: '%'},
				],

				timezoneOptions: [
					{v: -9, t: '-09:00'},
					{v: -8, t: '-08:00'},
					{v: -7, t: '-07:00'},
					{v: -6, t: '-06:00'},
					{v: -5, t: '-05:00'},
					{v: -4, t: '-04:00'},
					{v: -3, t: '-03:00'},
					{v: -2, t: '-02:00'},
					{v: -1, t: '-01:00'},
					{v: 0, t: '+00:00 UTC'},
					{v: 1, t: '+01:00'},
					{v: 2, t: '+02:00'},
					{v: 3, t: '+03:00'},
					{v: 4, t: '+04:00'},
					{v: 5, t: '+05:00'},
					{v: 6, t: '+06:00'},
					{v: 7, t: '+07:00'},
					{v: 8, t: '+08:00'},
					{v: 9, t: '+09:00'},
				],
				payoutModelOptions: [
					{v: 'CPA', t: 'CPA'},
					{v: 'CPI', t: 'CPI'},
					{v: 'CPR', t: 'CPR'},
					{v: 'CPC', t: 'CPC'},
					{v: 'CPM', t: 'CPM'},
					{v: 'CPS', t: 'CPS'},
				],


				getIntegrations: async function (){
					if (!this.integrationTypeOptions) {
						try {
							let resp = await Vue.$api.get('advertisers/getIntegrations');
							this.integrationTypeOptions = resp.integrations;
						} catch (e) {
							console.error(e);
						}
					}
					return this.integrationTypeOptions;
				},
			},

			country: {
				_options:[],
				_hash: {},
				async getOptions(reload){
					if (reload || this._options.length === 0){
						let resp = await Vue.$api.get('geo/getCountries', {params:{page_size:10000}});
						this._options = resp.records;
					}
					return this._options;
				}
			},
			region: {
				_options: [],
				_hash: {},
				async getOptions(reload, country = null) {
					if(reload || this._options.length === 0) {
						let resp = country ? await Vue.$api.get('geo/getRegions', {params:{page_size:10000, country }}) : await Vue.$api.get('geo/getRegions', {params:{page_size:10000}});
						this._options = resp.records;
					}
					return this._options;
				},
				async getOptionsByCountry(countries) {
					try {
						let resp = await Vue.$api.get('geo/getRegions', {params: countries});
						return resp.records;

					} catch (e) {
						throw e
					}
				}
			},

			city: {
				_options: [],
				_hash: {},
				async getOptions(reload, country = null) {
					if(reload || this._options.length === 0) {
						let resp = country ? await Vue.$api.get('geo/getCities', {params:{page_size:10000, country }}) : await Vue.$api.get('geo/getCities', {params:{page_size:10000}});
						this._options = resp.records;
					}
					return this._options;
				},
				async getOptionsByCountry(countries) {
					try {
						let resp = await Vue.$api.get('geo/getCities', {params: countries});
						return resp.records;
					} catch (e) {
						throw e
					}
				}
			},


			advertiser: {
				_booleans: [
					// 'status', 'fetch_api', 'auto_placements',
					// 'fetch_api_override_tracking',
					// 'fetch_api_override_events',
					// 'require_secret',
					// 'default_event_convert_payout', 'default_event_managed', 'default_require_validation',
					// 'api_load_active_only',
					// 'default_enable_smartlink', 'default_hide_offers',
				],
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						network_id: 1,
						status: false,
						name: '',
						fetch_api: false,
						api_auto_run: false,
						api_auto_create: false,
						api_auto_tag: false,
						api_sync_targeting: false,
						api_sync_caps: true,
						scan_required: false,
						ignore_caps: false,
						convert_on_payout: false,
						auto_publishers: []
						//
						// integration_type: null,
						// scan_frequency: -1,
						// redirect_type: 302,
						// score_offset: 302,
						// daily_subsource_limit: 0,
						// total_subsource_limit: 0,
						// integration_params: {},
						// tracking_link_params: [],
						// blocked_publishers: []
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('advertisers/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('advertisers/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},
			mmpAccount: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						network_id: 1,
						mmp: 'appsflyer',
						type: 'adnetwork',
						identifier: '',
						comments: '',
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('mmp-accounts/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('mmp-accounts/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},
			user: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						network_id: 0,
						first_name: '',
						last_name: '',
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('users/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('users/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},
			publisher: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						network_id: 1,
						status: false,
						support_advanced_privacy: true,
						name: '',
						comments: '',
						postbacks: [],
						risk: 70,
						payout_percent: 70
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('publishers/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('publishers/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
				getIntegrations: async function (){
					if (!this.integrationTypeOptions) {
						try {
							let resp = await Vue.$api.get('publishers/getIntegrations');
							this.integrationTypeOptions = resp.integrations;
						} catch (e) {
							console.error(e);
						}
					}
					return this.integrationTypeOptions;
				},
			},
			remotePublisher: {
				_booleans: [],
				_options: [],
				_hash: {},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('remote-entities/getPublisherOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},
			remotePublisherGroup: {
				_booleans: [],
				_options: [],
				_hash: {},
				_loading: null,
				async getOptions(reload){
					if (this._loading){
						await this._loading;
					}
					if (!this._options.length || reload) {
						this._loading = Vue.$api.get('remote-entities/getPublisherGroupOptions');
						let resp = await this._loading;
						this._loading = null;
						this._options = resp.options;
					}
					return this._options;
				}
			},
			asset: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						name: '',
						package_id: '',
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('assets/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('assets/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},
			offer: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance() {
					return {
						id: 0,
						status: 'draft',
						type: 'offer',
						name: '',
						comments: '',
						payout_model: 'CPI',
						payout: 0,
						advertiser_id: 0,
						currency: 'USD',
						mmp: null,
						is_locked: false,
						click_pacing_hour: 0,
						click_pacing_min: 0,
						impression_pacing_hour: 0,
						impression_pacing_min: 0,
						daily_click_cap: 0,
						daily_impression_cap: 0,
						daily_click_goal: 100000,
						daily_impression_goal: 0,
						override_click_goal: 0,
						override_impression_goal: 0,
						daily_conversion_cap: 0,
						monthly_conversion_cap: 0,
						daily_subsource_click_cap: 0,
						daily_subsource_limit: 0,
						total_subsource_limit: 0,
						click_url: '',
						impression_url: '',
						description: '',
						kpi_description: '',
						sub1_include: '',
						sub1_exclude: '',
						sub2_include: '',
						sub2_exclude: '',
						bundle_include: '',
						bundle_exclude: '',
						events: [],
						lock_tracking_links: false,
						bypass_machine: false,
						sign_clicks: false,
						direct_link: false,
						require_device_id: false,
						require_appname: false,
						require_bundle: false,
						incent: false,
						subOffers: [],
						keyword_mode: null,
						sub1_mode: null,
						asset_mode: null,
						geo_params: [],
						attr_format: 0,
						adv_sub1_postback: null,
						enable_daily_schedule: false,
						daily_schedule: Array.from({length: 7}).map(dayRow => {
							return Array.from({length: 24}).map(hourCell => {
								return {
									clicks: 0
								};
							});
						}),
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('offers/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(params){
					if (!params){
						params = {};
					}
					let resp = await Vue.$api.get('offers/getOptions', {params});
					return resp.options;
				},
				async getSelectedOptions(str){
					let selected = [];
					if (str) {
						str += '';
						let itemList = str.split(',').map(r => +r).filter(r => r);
						if (itemList.length) {
							let resp = await Vue.$api.get('offers/getOptionsById', {params: {offer_id: itemList.join(',')}});
							selected = resp.options;
						}
					}
					return selected;
				},
				async prepareFromExternal(extOfferId){
					let resp = await Vue.$api.get('offers/prepareFromExternal/'+extOfferId);
					return resp.offer;
				}
			},
			dataControl: {
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						network_id: null,
						name: '',
						platform: '',
						countries: '',
						keyword_override: '',
						sub1_override: '',
						sub2_override: '',
						asset_override: [],
						num_sources: 100,
						sub1_rotate: true,
						sub1_generate_mode: 'random',
						keyword_select_mode: 'priority'
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('data-control/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('data-control/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
			},
			publisherFeed: {
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						name: '',
						payout_model: 'CPM',
						payout_percent: 0,
						timeout: 0,
						network_id: 1,
						publisher_id: null,
						status: 0,
						comments: '',
						description: '',
						countries: 'WW',
						type: 0,
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('publisher-feeds/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('publisher-feeds/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
			},
			placement: {
				_options: [],
				_hash: {},
				newInstance(offer){
					return {
						id: 0,
						name: '',
						currency: offer ? offer.currency : 'USD',
						payout: offer ? offer.payout : 0,
						payout_model: offer ? offer.payout_model : 'CPI',
						offer_id: offer ? offer.id : null,
						advertiser_id: offer ? offer.advertiser_id : null,
						custom_click_url: '',
						custom_impression_url: '',
						fallback_click_url: '',
						fallback_impression_url: '',
						daily_conversion_cap: 0,
						daily_click_cap: 0,
						daily_impression_cap: 0,
						daily_subsource_click_cap: 0,
						daily_subsource_limit: 0,
						total_subsource_limit: 0,
						click_pacing_hour: 0,
						click_pacing_min: 0,
						impression_pacing_hour: 0,
						impression_pacing_min: 0,
						click2impression_mode: 'none',
						impression_ratio: 0,
						redirect_type: 0,
						skip_geo_check: false,
						remote_enabled: false,
						remote_id: '',
						remote_status: 'paused',
						remote_hourly_installs: 0,
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('placements/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(params){
					if (!params){
						params = {};
					}
					let resp = await Vue.$api.get('placements/getOptions', {params});
					return resp.options;

				},
				async getSelectedOptions(str){
					let selected = [];
					if (str) {
						str += '';
						let itemList = str.split(',').map(r => +r).filter(r => r);
						if (itemList.length) {
							let resp = await Vue.$api.get('placements/getOptionsById', {params: {placement_id: itemList.join(',')}});
							selected = resp.options;
						}
					}
					return selected;
				}
			},
			autoCampaignJob: {
				_options: [],
				_hash: {},
				statusOptions: [
					{v: 'draft', t: 'Draft'},
					{v: 'live', t: 'Live'},
					{v: 'complete', t: 'Complete'},
					{v: 'paused', t: 'Paused'},
					{v: 'stopped', t: 'Stopped'},
					{v: 'error', t: 'Error'},
				],
				newInstance(){
					return {
						id: 0,
						placement_id: null,
						publisher_id: null,
						remote_publisher_group_id: null,
						send_impressions: false,
						platform: '',
						countries: '',
						regions: '',
						cities: '',
						exclude_regions: '',
						exclude_cities: '',
						exclude_publishers: '',
						isps: '',
						status: 'draft',
						schedule: Array.from({length: 7}).map(dayRow => {
							return Array.from({length: 24}).map(hourCell => {
								return {
									clicks: 0
								};
							});
						}),
						min_os_version: 0,
						filters: [],
						group_filters: [],
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('auto-campaigns/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('placements/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
			},
			ac: {
				_options: [],
				_hash: {},
				statusOptions: [
					{v: 'draft', t: 'Draft'},
					{v: 'live', t: 'Live'},
					{v: 'complete', t: 'Complete'},
					{v: 'paused', t: 'Paused'},
					{v: 'stopped', t: 'Stopped'},
					{v: 'error', t: 'Error'},
				],
				newInstance(){
					return {
						id: 0,
						placement_id: null,
						publisher_id: null,
						remote_publisher_group_id: null,
						send_impressions: false,
						platform: '',
						countries: '',
						status: 'draft',
						schedule: Array.from({length: 7}).map(dayRow => {
							return Array.from({length: 24}).map(hourCell => {
								return {
									clicks: 0
								};
							});
						}),
						filters: [],
						group_filters: [],
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('ac/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('placements/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
			},
			autoImportJob: {
				_options: [],
				_hash: {},
				statusOptions: [
					{v: 'active', t: 'Active'},
					{v: 'paused', t: 'Paused'},
				],
				newInstance(){
					return {
						id: 0,
						publisher_id: null,
						platform: '',
						country: '',
						remote_publisher_id: null,
						remote_offer_id: null,
						limit: 0,
						status: 'paused',
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('auto-imports/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
			},
			creative: {
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						name: null,
						url: null
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('creatives/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('creatives/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
			},
			tag: {
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						width: 0,
						height: 0,
						name: null,
						is_rtb: false,
						banners: [],
						background_placements: [],
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('tags/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('tags/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
			},
			campaign: {
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						// width: 0,
						// height: 0,
						bid: 0,
						daily_impression_cap: 0,
						daily_budget: 0,
						name: null,
						tag_id: null,
						status: 'draft',
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('campaigns/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('campaigns/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
			},
			network: {
				_options: [],
				_hash: {},
				newInstance(){
					return {
						id: 0,
						name: '',
						slug: '',
						tracking_base_url: '',
						assigned_domains: [],
					};
				},
				async get(id, reload){
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('networks/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload){
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('networks/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
			},

			mb: {
				platform: {
					_booleans: [],
					_options: [],
					_hash: {},
					newInstance(){
						return {
							id: 0,
							status: false,
							name: '',
							comments: '',
						};
					},
					async get(id, reload){
						if (!this._hash[id] || reload) {
							let resp = await Vue.$api.get('mb/platforms/get/' + id);
							this._hash[id] = resp.entity;
						}
						return this._hash[id];
					},
					async getOptions(reload){
						if (!this._options.length || reload) {
							let resp = await Vue.$api.get('mb/platforms/getOptions');
							this._options = resp.options;
						}
						return this._options;
					}
				},
				campaign: {
					_booleans: [],
					_options: [],
					_hash: {},
					newInstance(){
						return {
							id: 0,
							mb_platform_id: 1,
							status: false,
							name: '',
							comments: '',
							remote_data: {},
							remote_id: null
						};
					},
					async get(id, reload){
						if (!this._hash[id] || reload) {
							let resp = await Vue.$api.get('mb/campaigns/get/' + id);
							this._hash[id] = resp.entity;
						}
						return this._hash[id];
					},
					async getOptions(reload){
						if (!this._options.length || reload) {
							let resp = await Vue.$api.get('mb/campaigns/getOptions');
							this._options = resp.options;
						}
						return this._options;
					}
				},
			}
		};


		Vue.$dwData = api;
		Vue.prototype.$dwData = api;
	}

};


Vue.use(DataService);

export default DataService;
